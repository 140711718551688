import { useLocation } from "react-router-dom";

import { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

import PageLoader from "../Components/PageLoader";
import Basic from "../Layouts/Basic";
import BasicLayoutCommunityAdmin from "../Layouts/communityAdmin/LayoutV2";
import EmailPreview from "../Pages/community/admin/rewards/EmailPreview";

const Home = lazy(() => import(/* webpackChunkName: "Home" */ '../Pages/Home'));
const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ '../Pages/Blog'));
const Race = lazy(() => import(/* webpackChunkName: "Race" */ '../Pages/Race'));
const Aboutus = lazy(() => import(/* webpackChunkName: "Aboutus" */ '../Pages/Aboutus'));
const Partner = lazy(() => import(/* webpackChunkName: "Partner" */ '../Pages/Partner'));
const Contactus = lazy(() => import(/* webpackChunkName: "Contactus" */ '../Pages/Contactus'));
const Privacy = lazy(() => import(/* webpackChunkName: "Privacy" */ '../Pages/Privacy'));
const Terms = lazy(() => import(/* webpackChunkName: "Terms" */ '../Pages/Terms'));
const Faq = lazy(() => import(/* webpackChunkName: "Faq" */ '../Pages/Faq'));
const NotFound = lazy(() => import('../Pages/404'))

const Login = lazy(() => import(/* webpackChunkName: "Login" */ '../Pages/Login'));
const Logout = lazy(() => import(/* webpackChunkName: "Login" */ '../Pages/Logout'));
const LoginPermata = lazy(() => import(/* webpackChunkName: "LoginDBS" */ '../Pages/LoginPermata'));
const LoginAstra = lazy(() => import(/* webpackChunkName: "LoginDBS" */ '../Pages/LoginAstra'));
const LoginImitate = lazy(() => import(/* webpackChunkName: "LoginIframe" */ '../Pages/LoginImitate'));
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ '../Pages/Signup'));
const SignupPermata = lazy(() => import(/* webpackChunkName: "SignupDBS" */ '../Pages/SignUpPermataPage'));
const SignupAstra= lazy(() => import(/* webpackChunkName: "SignupDBS" */ '../Pages/SignupAstra24'));
const Forgot = lazy(() => import(/* webpackChunkName: "Forgot" */ '../Pages/Forgot'));
const Reset = lazy(() => import(/* webpackChunkName: "Reset" */ '../Pages/Reset'));
const Verification = lazy(() => import(/* webpackChunkName: "Verification" */ '../Pages/Verification'));
const ComproOverview = lazy(() => import('../Pages/compro/ComproOverview'))
const ComproPortfolio = lazy(() => import('../Pages/compro/ComproPortfolio'))
const ComproList = lazy(() => import('../Pages/compro/ComproList'))
const B2b  = lazy(() => import('../Pages/compro/B2b'))
const Dashboard = lazy(() => import(/* webpackChunkName: "Home" */ '../Pages/Dashboard'));
const Setting = lazy(() => import(/* webpackChunkName: "Home" */ '../Pages/SettingNew'));
const Activity = lazy(() => import(/* webpackChunkName: "Home" */ '../Pages/Activity'));
const ConnectApps = lazy(() => import("../Pages/ConnectApps"))

// const Badge = lazy(() => import(/* webpackChunkName: "Home" */ '../Pages'));
// twibbon
const Twibbon = lazy(() => import("../Pages/twibbon/twibbon"));
const TwibbonDownload = lazy(() => import('../Pages/twibbon/twibbonDownload'))
const TwibbonSize = lazy(() => import('../Pages/twibbon/twibbonSize'))

// event
const EventDetails = lazy(() => import('../Pages/event/EventDetails'))
const Workoutsebuse = lazy(() => import('../Pages/event/Sebuse2022-workout'))
const Workoutsebuserpm = lazy(() => import('../Pages/event/Sebuserpm2022-workout'))
const RegistWvi = lazy(() => import('../Pages/event/Wviglobal6k-regist'))

// race monitoring
const RaceRegistList = lazy(() => import('../Pages/racemonitoring/RegistList'))
const LoginRaceRegistList= lazy(() => import('../Pages/LoginRaceRegistList'));

const Ebib = lazy(() => import('../Pages/event/Ebib'))
const Ecert = lazy(() => import('../Pages/event/Ecert'))

const FirstStep = lazy(() => import('../Pages/event/step/components/FirstStep'))
const SecondStep = lazy(() => import('../Pages/event/step/components/SecondStep'))
const SummaryStep = lazy(() => import('../Pages/event/step/components/summaryStep'))
const Finish = lazy(() => import('../Pages/event/step/components/finish'));

// mfc page
const M4cPartnerDetail = lazy(() => import('../Pages/m4c/DetailPartner'))
const M4cPartner = lazy(() => import('../Pages/m4c/Partners_v2'))
const M4cLandingPage = lazy(() => import('../Pages/m4c/LandingPage'))
const M4cFaq = lazy(() => import('../Pages/m4c/Faq'))
const M4cApply = lazy(() => import('../Pages/m4c/Apply'))

// mfc admin
const M4cAdminLogin = lazy(() => import('../Pages/m4cAdmin/Login'))
const M4cAdminDasboard = lazy(() => import('../Pages/m4cAdmin/Dashboard'))
const M4cAdminInfo = lazy(() => import('../Pages/m4cAdmin/Info'))
const M4cAdminMenu = lazy(() => import('../Pages/m4cAdmin/Menu'))
const M4cAdminRedeem = lazy(() => import('../Pages/m4cAdmin/Redeem'))
const M4cAdminReview = lazy(() => import('../Pages/m4cAdmin/Review'))

// community
const CommunityAct = lazy(() => import('../Pages/community/CommunityAct'));
const CommunityLeaderboard = lazy(() => import('../Pages/community/CommunityLeaderboard'));
const MyCommunity = lazy(() =>  import ('../Pages/community/Community'));
const MyCommunityDetail = lazy(() => import ('../Pages/community/CommunityDetail'));
const CommunityCheckin = lazy(() => import ('../Pages/community/CommunityCheckin'));
const CommunityJoin = lazy(() => import('../Pages/community/CommunityJoin'));
const CommunityBook = lazy(() => import('../Pages/community/CommunityBook'));
const CommunityPointHistory = lazy(() => import ('../Pages/community/PointHistory'));
const CommunityRewards = lazy(() => import('../Pages/community/Rewards'));
const CommunityDetail = lazy(() => import("../Pages/community/CommunityDetail"))

// community admin
const CommunityAdminLogin = lazy(() => import('../Pages/community/admin/Login'))
const CommunityAdminDashboard = lazy(() => import('../Pages/community/admin/Dashboard'))
const CommunityAdminEventDetail = lazy(() => import('../Pages/community/admin/EventDetail'))
const CommunityAdminInfo = lazy(() => import('../Pages/community/admin/Info'))
const CommunityAdminLogo = lazy(() => import('../Pages/community/admin/Logo'))
const CommunityAdminMember = lazy(() => import('../Pages/community/admin/Member'))
const CommunityAdminEvent = lazy(() => import("../Pages/community/admin/Event"))
const CommunityAdminRewards = lazy(() => import("../Pages/community/admin/rewards/Rewards"))
const CommunityPointHistoryAdmin = lazy(() => import("../Pages/community/admin/point_history/Point_History"))
const RedeemMenuAdmin = lazy(() => import("../Pages/community/admin/redeem/RedeemMenu"))
const ActivitiesMenuAdmin = lazy(() => import("../Pages/community/admin/activities/Activities"))
const DetailActivities = lazy(() => import("../Pages/community/admin/activities/DetailActivities"))

//CHALLENGE
const ChallengeList = lazy(() => import("../Pages/challenges/List"))
const ChallengeOverview = lazy(() => import("../Pages/challenges/Overview"))
const ChallengeDetail = lazy(() => import("../Pages/challenges/Detail"))
const ChallengeLeaderboard = lazy(() => import("../Pages/challenges/Leaderboard"))



const isLoggedIn = localStorage.getItem("__xtropt") !== '';

const RedirectEvent = () =>{
    var location = useLocation()

    console.log(window.location.href)
    if(window.location.href.split('?')[1] != undefined){
        window.location.href = "https://event.cause.id"+location.pathname.replace('/event','')+'?'+window.location.href.split('?')[1];

    }else{
        window.location.href = "https://event.cause.id"+location.pathname.replace('/event','');

    }
    return null;
}

const RedirectOnly = () =>{
    var location = useLocation()

    console.log(window.location.href)
    if(window.location.href.split('?')[1] != undefined){
        window.location.href = "https://alt.cause.id"+location.pathname+'?'+window.location.href.split('?')[1];
    }else{
        window.location.href = "https://alt.cause.id"+location.pathname;
    }
    return null;
}

const RedirectPress = () =>{
    var location = useLocation()

    console.log(window.location.href)
    if(window.location.href.split('?')[1] != undefined){
        window.location.href = "https://alt.cause.id/press"
    }else{
        window.location.href = "https://alt.cause.id/press"
    }
    return null;
}

const RedirectEventDetail = () =>{
    var location = useLocation()

    console.log(window.location.href)
    if(window.location.href.split('?')[1] != undefined){
        window.location.href = "https://event.cause.id"+location.pathname.replace('redirectevent/','')+'/eventdetails'+'?'+window.location.href.split('?')[1];
    }else{
        window.location.href = "https://event.cause.id"+location.pathname.replace('redirectevent/','')+'/eventdetails';
    }
    return null;
}
export default function Router() {
    const ROUTE = useRoutes([
        {
            path: '/',
            element: <Basic />,
            children:[
                {
                    path:'/',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Home/>
                    </Suspense>
                },
                {
                    path:'/blog',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Blog/>
                    </Suspense>
                },
                {
                    path:'/race',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Race/>
                    </Suspense>
                },
                {
                    path:'/aboutus',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Aboutus/>
                    </Suspense>
                },
                {
                    path:'/partner?',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Partner/>
                    </Suspense>
                },
                {
                    path:'/contactus',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Contactus/>
                    </Suspense>
                },
                {
                    path:'/privacypolicy',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Privacy/>
                    </Suspense>
                },
                {
                    path:'/termsandcondition',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Terms/>
                    </Suspense>
                },
                {
                    path:'/faq',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Faq/>
                    </Suspense>
                },
                {
                    path:'/login',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Login/>
                    </Suspense>
                }, 
                {
                    path:'/logout',
                    element:
                    <Suspense fallback={<PageLoader></PageLoader>}>
                        <Logout/>
                    </Suspense>
                },                
                {
                    path:'/login-imitate',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <LoginImitate/>
                    </Suspense>
                },
                {
                    path:'/signup',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Signup/>
                    </Suspense>
                },
                {
                    path:'/forgot',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Forgot/>
                    </Suspense>
                },
                {
                    path:'/reset',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Reset/>
                    </Suspense>
                },
                {
                    path:'/verification',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Verification/>
                    </Suspense>
                },
                {
                    path : '/verification/:token',
                    element : <Suspense fallback={<PageLoader />}>{<Verification />}</Suspense>
                },
                {
                    path:'/dashboard',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Dashboard/>
                    </Suspense>
                },
                {
                    path:'/settings',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Setting/>
                    </Suspense>
                },
                {
                    path:'/setting',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Setting/>
                    </Suspense>
                },
                {
                    path:'/activities',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <Activity/>
                    </Suspense>
                },
                {
                    path: '/mycommunities',
                    element: <Suspense fallback={<PageLoader></PageLoader>}><MyCommunity /></Suspense>,
                },
                {
                    path: 'move4coffee/partner',
                    element: <Suspense fallback={<PageLoader />}><M4cPartner/></Suspense>,
                },
                {
                    path: 'move4coffee',
                    element: <Suspense fallback={<PageLoader />}><M4cLandingPage/></Suspense>,
                },
                {
                    path:'move4coffee/faq',
                    element: <Suspense fallback={<PageLoader />}><M4cFaq/></Suspense>,
                },
                {
                    path: 'move4coffee/apply/:type',
                    element : <Suspense fallback={<PageLoader />}><M4cApply/></Suspense>,
                },
                {
                    path : 'move4coffee/partner/:slug',
                    element : <Suspense fallback={<PageLoader />}><M4cPartnerDetail /></Suspense>
                },
                // REDIRECT
                {
                    path:'press/*',
                    element:<RedirectPress></RedirectPress>,
                },
                {
                    path:'event/*',
                    element:<RedirectEvent></RedirectEvent>,
                },
                {
                    path:'redirect/*',
                    element:<RedirectOnly></RedirectOnly>,
                },
                {
                    path:'redirectevent/*',
                    element:<RedirectEventDetail></RedirectEventDetail>,
                },
                {
                    path:'howto/*',
                    element:<RedirectOnly></RedirectOnly>,
                },
                // SEBUSE
                {
                    path:'sebuse2022-workout',
                    element: <Suspense fallback={<PageLoader />}><Workoutsebuse></Workoutsebuse></Suspense>,
                },
                {
                    path:'sebuserpm2022-workout',
                    element: <Suspense fallback={<PageLoader />}><Workoutsebuserpm></Workoutsebuserpm></Suspense>,
                },
                // WVI
                {
                    path:'wviglobal6k-status',
                    element:<Suspense fallback={<PageLoader />}><RegistWvi></RegistWvi></Suspense>,
                },
                // COMPRO
                {
                    path:'company-profile',
                    element: <Suspense fallback={<PageLoader />}><ComproOverview/></Suspense>,
                },
                {
                    path:'company-profile/portfolio',
                    element:<Suspense fallback={<PageLoader />}><ComproPortfolio/></Suspense>,
                },
                {
                    path:'company-profile/portfolio-list/:year',
                    element:<Suspense fallback={<PageLoader />}><ComproList/></Suspense>,
                },
                {
                    path:'b2b',
                    element: <Suspense fallback={<PageLoader />}><B2b/></Suspense>,
                },
                {
                    path: '/twibbon/:raceName',
                    element : <Suspense fallback={<PageLoader />}><Twibbon /></Suspense>
                },
                {
                    path : '/twibbon-download',
                    element : <Suspense fallback={<PageLoader />}><TwibbonDownload /></Suspense>
                },
                {
                    path : '/twibbon-size',
                    element : <Suspense fallback={<PageLoader />}><TwibbonSize /></Suspense>
                },
                {
                    path: '/:id/eventdetails',
                    element: <Suspense fallback={<PageLoader></PageLoader>}>
                    <EventDetails /></Suspense>,
                },
                {
                    path : 'move4coffee/partner/:slug',
                    element : <Suspense fallback={<PageLoader />}><M4cPartnerDetail /></Suspense>
                },
                // REDIRECT
                {
                    path:'press/*',
                    element:<RedirectPress></RedirectPress>,
                },
                {
                    path:'event/*',
                    element:<RedirectEvent></RedirectEvent>,
                },
                {
                    path:'redirect/*',
                    element:<RedirectOnly></RedirectOnly>,
                },
                {
                    path:'redirectevent/*',
                    element:<RedirectEventDetail></RedirectEventDetail>,
                },
                {
                    path:'howto/*',
                    element:<RedirectOnly></RedirectOnly>,
                },
                // SEBUSE
                {
                    path:'sebuse2022-workout',
                    element: <Suspense fallback={<PageLoader />}><Workoutsebuse></Workoutsebuse></Suspense>,
                },
                {
                    path:'sebuserpm2022-workout',
                    element: <Suspense fallback={<PageLoader />}><Workoutsebuserpm></Workoutsebuserpm></Suspense>,
                },
                // WVI
                {
                    path:'wviglobal6k-status',
                    element:<Suspense fallback={<PageLoader />}><RegistWvi></RegistWvi></Suspense>,
                },
                // COMPRO
                {
                    path:'company-profile',
                    element: <Suspense fallback={<PageLoader />}><ComproOverview/></Suspense>,
                },
                {
                    path:'company-profile/portfolio',
                    element:<Suspense fallback={<PageLoader />}><ComproPortfolio/></Suspense>,
                },
                {
                    path:'company-profile/portfolio-list/:year',
                    element:<Suspense fallback={<PageLoader />}><ComproList/></Suspense>,
                },
                {
                    path:'b2b',
                    element: <Suspense fallback={<PageLoader />}><B2b/></Suspense>,
                },
                {
                    path: '/twibbon/:raceName',
                    element : <Suspense fallback={<PageLoader />}><Twibbon /></Suspense>
                },
                {
                    path : '/twibbon-download',
                    element : <Suspense fallback={<PageLoader />}><TwibbonDownload /></Suspense>
                },
                {
                    path : '/twibbon-size',
                    element : <Suspense fallback={<PageLoader />}><TwibbonSize /></Suspense>
                },
                {
                    path : '/:raceName/ebib',
                    element : <Suspense fallback={<PageLoader />}><Ebib /></Suspense>
                },
                {
                    path : '/:raceName/ecert',
                    element : <Suspense fallback={<PageLoader />}><Ecert /></Suspense>
                },
                {
                    path : '/:race/step-1',
                    element : <Suspense fallback={<PageLoader />}>{<FirstStep />}</Suspense>
                },
                {
                    path : '/:race/step-2',
                    element: <Suspense fallback={<PageLoader />}>{<SecondStep />}</Suspense>
                },
                {
                    path : '/:race/summary',
                    element : <Suspense fallback={<PageLoader />}>{<SummaryStep />}</Suspense>
                },
                {
                    path : '/:race/finish',
                    element : <Suspense fallback={<PageLoader />}>{<Finish />}</Suspense>
                },
                {
                    path : '/challenges',
                    element : <Suspense fallback={<PageLoader />}>{<ChallengeList />}</Suspense>
                },
                {
                    path : '/challenges/:code',
                    element : <Suspense fallback={<PageLoader />}>{<ChallengeOverview />}</Suspense>
                },
                {
                    path : '/challenges/:code/detail',
                    element : <Suspense fallback={<PageLoader />}>{<ChallengeDetail />}</Suspense>
                },
                {
                    path : '/challenges/:code/leaderboard',
                    element : <Suspense fallback={<PageLoader />}>{<ChallengeLeaderboard />}</Suspense>
                },
                {
                    path: 'connect-apps',
                    element: <Suspense fallback={<PageLoader />}>{<ConnectApps />}</Suspense>
                }
            ]
        },
        {
            path: '/',
            element: <Basic showLogin={false}></Basic>,
            children: [
                {
                    path:'/loginSuperbank',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <LoginPermata/>
                    </Suspense>
                },
                {
                    path:'/signupSuperbank',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <SignupPermata/>
                    </Suspense>
                }
            ]
        },
        {
            path: '/',
            element: <Basic showLogin={false}></Basic>,
            children: [
                {
                    path:'/loginAstra',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <LoginAstra/>
                    </Suspense>
                },
                {
                    path:'/signupAstra',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <SignupAstra/>
                    </Suspense>
                }
            ]
        },
        {
            path: '/',
            element: <Basic showLogin={false}></Basic>,
            children: [
                {
                    path:'/loginHsbc',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <LoginPermata/>
                    </Suspense>
                },
                {
                    path:'/signupHsbc',
                    element:<Suspense fallback={<PageLoader></PageLoader>}>
                        <SignupPermata/>
                    </Suspense>
                }
            ]
        },
        {
            path: '/mycommunities',
            children: [
                {
                    path: '/mycommunities/:slug/act',
                    element: <Suspense fallback={<PageLoader></PageLoader>}><CommunityAct/></Suspense>,
                },
                {
                    path: '/mycommunities/:slug/pointhistory',
                    element: <Suspense fallback={<PageLoader />}><CommunityPointHistory /></Suspense>,
                },
                {
                    path: '/mycommunities/:slug/leaderboard',
                    element: <Suspense fallback={<PageLoader />}><CommunityLeaderboard /></Suspense>,
                },
                {
                    path: '/mycommunities/:slug/rewards',
                    element: <Suspense fallback={<PageLoader />}><CommunityRewards /></Suspense>,
                },
                {
                    path: '/mycommunities/:slug',
                    element: <Suspense fallback={<PageLoader />}><CommunityDetail /></Suspense>,
                },
                {
                    path:'mycommunities/:slug/pointhistory',
                    element : <Suspense fallback={<PageLoader />}><CommunityPointHistory/></Suspense>,
                }
            ]
        },
        {
            path: '/community',
            children: [
                {
                    path: '/community/checkin/:id/:code',
                    element: <Suspense fallback={<PageLoader />}><CommunityCheckin /></Suspense>,
                },
                {
                    path: '/community/book/:code',
                    element: <Suspense fallback={<PageLoader />}><CommunityBook /></Suspense>,
                },
                {
                    path: '/community/admin/login',
                    element: <Suspense fallback={<PageLoader />}><CommunityAdminLogin /></Suspense>,
                },
                {
                    path: '/community/admin/dashboard',
                    element: <Suspense fallback={<PageLoader />}><CommunityAdminDashboard /></Suspense>,
                },
                {
                    path: '/community/admin/event/:id',
                    element: <Suspense fallback={<PageLoader />}><CommunityAdminEventDetail /></Suspense>,
                },
                {
                    path : '/community/community-event',
                    element : <Suspense fallback={<PageLoader />}><CommunityAdminEvent /></Suspense>,
                },
                {
                    path : '/community/community-info',
                    element : <Suspense fallback={<PageLoader />}><CommunityAdminInfo /></Suspense>,
                },
                {
                    path : '/community/community-logo',
                    element : <Suspense fallback={<PageLoader />}><CommunityAdminLogo /></Suspense>,
                },
                {
                    path : '/community/community-member',
                    element : <Suspense fallback={<PageLoader />}><CommunityAdminMember /></Suspense>,
                },
                {
                    path : '/community/community-rewards',
                    element : <Suspense fallback={<PageLoader />}><CommunityAdminRewards /></Suspense>,
                },
                {
                    path : '/community/community-point-history/:id',
                    element : <Suspense fallback={<PageLoader />}><CommunityPointHistoryAdmin /></Suspense>,
                },
                {
                    path : '/community/community-redeem',
                    element : <Suspense fallback={<PageLoader />}><RedeemMenuAdmin /></Suspense>,
                },
                {
                    path : '/community/community-activities',
                    element : <Suspense fallback={<PageLoader />}><ActivitiesMenuAdmin /></Suspense>,
                },
                {
                    path : '/community/community-activities/details/:param', 
                    element : <Suspense fallback={<PageLoader />}><BasicLayoutCommunityAdmin><DetailActivities /></BasicLayoutCommunityAdmin></Suspense>,
                },
                {
                    path : '/community/join/:code',
                    element : <Suspense fallback={<PageLoader />}><CommunityJoin /></Suspense>
                }
            ]
        },
        {
            path: '/move4coffee/admin',
            children: [
                {
                    path:'/move4coffee/admin/login',
                    element : <Suspense fallback={<PageLoader />}><M4cAdminLogin/></Suspense>,
                },
                {
                    path:'/move4coffee/admin/dashboard',
                    element: <Suspense fallback={<PageLoader />}><M4cAdminDasboard/></Suspense>,
                },
                {
                    path:'/move4coffee/admin/redeem',
                    element: <Suspense fallback={<PageLoader />}><M4cAdminRedeem/></Suspense>,
                },
                {
                    path:'/move4coffee/admin/info',
                    element: <Suspense fallback={<PageLoader />}><M4cAdminInfo/></Suspense>,
                },
                {
                    path:'/move4coffee/admin/menu',
                    element: <Suspense fallback={<PageLoader/>}><M4cAdminMenu/></Suspense>,
                },
                {
                    path:'/move4coffee/admin/review',
                    element: <Suspense fallback={<PageLoader />}><M4cAdminReview/></Suspense>,
                },
            ]
        },
        {
            path : '/preview',
            element : <Suspense fallback={<PageLoader />}>{<EmailPreview />}</Suspense>
        },
        {
            path : '/:race_codename/list',
            element : <Suspense fallback={<PageLoader />}><RaceRegistList /></Suspense>
        },
        {
            path : '/:race_codename/list/login',
            element : <Suspense fallback={<PageLoader />}><LoginRaceRegistList /></Suspense>
        },
        {
            path:'*',
            element:<Basic></Basic>,
            children:[
                {
                    path:'*',
                    element: 
                <Suspense fallback={<PageLoader/>}><NotFound/></Suspense>
                }
            ]
        }
    ])
    return ROUTE;
}